@use '../mixins';

hr {
    width: 100%;
    height: 1px;
    margin: 0;
    padding: 0;
    background-color: rgb(var(--textColor));
    opacity: 0.2;
    border: none;

    &.vertical {
        width: 1px;
        height: 100%;

        [data-theme='br'] & {
            width: 2px;
        }
    }
}

.inverted hr {
    background-color: rgb(var(--invertedTextColor));
}
