/* stylelint-disable custom-property-empty-line-before */
@use 'mixins/responsive';

:root,
[data-theme] {
    --text-font-family: var(--font-family-lato);
    --text-line-height: 1.6;

    --heading-font-family: var(--font-family-oswald);
    --heading-line-height: 1.2;

    /* Font Weights */
    --font-weight-regular: 400;
    --font-weight-semi-bold: 600;
    --font-weight-bold: 700;
    --font-weight-extra-bold: 900;

    --heading-large-font-size: 2.5em;
    --heading-large-color: var(--titleColor);
    --heading-large-font-family: var(--heading-font-family);
    --heading-large-font-weight: var(--font-weight-bold);
    --heading-large-line-height: var(--heading-line-height);

    --heading-medium-font-size: 1.75em;
    --heading-medium-color: var(--titleColor);
    --heading-medium-font-family: var(--heading-font-family);
    --heading-medium-font-weight: var(--font-weight-bold);
    --heading-medium-line-height: var(--heading-line-height);

    --heading-small-font-size: 1.125em;
    --heading-small-color: var(--titleColor);
    --heading-small-font-family: var(--heading-font-family);
    --heading-small-font-weight: var(--font-weight-bold);
    --heading-small-line-height: var(--heading-line-height);

    --heading-x-small-font-size: 0.875em;
    --heading-x-small-color: var(--lighterTextColor);
    --heading-x-small-font-family: var(--heading-font-family);
    --heading-x-small-font-weight: var(--font-weight-bold);
    --heading-x-small-line-height: var(--heading-line-height);
    --heading-x-small-text-transform: uppercase;

    --article-heading-large-font-size: 2.5em;
    --article-heading-large-color: var(--titleColor);
    --article-heading-large-font-family: var(--heading-font-family);
    --article-heading-large-font-weight: var(--font-weight-bold);
    --article-heading-large-line-height: 1em;

    --article-heading-medium-font-size: 1.5em;
    --article-heading-medium-color: var(--titleColor);
    --article-heading-medium-font-family: var(--heading-font-family);
    --article-heading-medium-font-weight: var(--font-weight-bold);
    --article-heading-medium-line-height: 1em;

    --article-heading-small-font-size: 1.125em;
    --article-heading-small-color: var(--titleColor);
    --article-heading-small-font-family: var(--heading-font-family);
    --article-heading-small-font-weight: var(--font-weight-bold);
    --article-heading-small-line-height: 1.333em;

    --paragraph-large-font-size: 1.125em;
    --paragraph-large-color: var(--textColor);
    --paragraph-large-font-family: var(--text-font-family);
    --paragraph-large-font-weight: var(--font-weight-semi-bold);
    --paragraph-large-line-height: var(--text-line-height);

    --paragraph-medium-font-size: 1em;
    --paragraph-medium-color: var(--textColor);
    --paragraph-medium-font-family: var(--text-font-family);
    --paragraph-medium-font-weight: var(--font-weight-regular);
    --paragraph-medium-line-height: var(--text-line-height);

    --caption-medium-font-size: 0.875em;
    --caption-medium-color: var(--textColor);
    --caption-medium-font-family: var(--text-font-family);
    --caption-medium-font-weight: var(--font-weight-bold);
    --caption-medium-line-height: 1.285;
    --caption-medium-text-transform: uppercase;

    --caption-small-font-size: 0.75em;
    --caption-small-color: var(--textColor);
    --caption-small-font-family: var(--text-font-family);
    --caption-small-font-weight: var(--font-weight-bold);
    --caption-small-line-height: 1.5;
    --caption-small-text-transform: uppercase;

    --tag-medium-font-size: 0.875em;
    --tag-medium-color: var(--textColor);
    --tag-medium-font-family: var(--heading-font-family);
    --tag-medium-font-weight: var(--font-weight-semi-bold);
    --tag-medium-line-height: 1.285;
    --tag-medium-letter-spacing: 0.02em;
    --tag-medium-text-transform: uppercase;

    --tag-small-font-size: 0.75em;
    --tag-small-color: var(--textColor);
    --tag-small-font-family: var(--heading-font-family);
    --tag-small-font-weight: var(--font-weight-semi-bold);
    --tag-small-line-height: 1.5;
    --tag-small-letter-spacing: 0.02em;
    --tag-small-text-transform: uppercase;

    --link-medium-font-size: 1em;
    --link-medium-color: var(--textColor);
    --link-medium-font-family: var(--text-font-family);
    --link-medium-font-weight: var(--font-weight-bold);
    --link-medium-line-height: 1.5;

    --link-small-font-size: 0.875em;
    --link-small-color: var(--textColor);
    --link-small-font-family: var(--text-font-family);
    --link-small-font-weight: var(--font-weight-bold);
    --link-small-line-height: 1.4;

    --link-list-font-size: 1em;
    --link-list-color: var(--textColor);
    --link-list-font-family: var(--text-font-family);
    --link-list-font-weight: var(--font-weight-bold);
    --link-list-line-height: 1.25;

    @include responsive.responsive(m, below) {
        --heading-large-font-size: 1.75em;
        --article-heading-large-font-size: 1.75em;
    }
}

[data-theme='vp'],
[data-theme='vpbe'],
[data-theme='vn'] {
    --heading-large-font-size: 2.25em;

    --heading-small-font-size: 1.25em;
    --heading-small-font-family: var(--text-font-family);

    --heading-x-small-font-weight: var(--font-weight-regular);
    --heading-x-small-letter-spacing: 0.08em;

    --article-heading-large-font-size: 3em;
    --article-heading-large-line-height: var(--heading-line-height);

    --article-heading-medium-font-size: 1.25em;
    --article-heading-medium-line-height: var(--heading-line-height);
    --article-heading-medium-font-family: var(--text-font-family);

    --article-heading-small-font-size: 1em;
    --article-heading-small-line-height: var(--heading-line-height);
    --article-heading-small-font-family: var(--text-font-family);

    --paragraph-large-font-size: 1em;

    --caption-medium-text-transform: initial;
    --caption-medium-letter-spacing: 0.02em;
    --caption-small-font-size: 0.875em;
    --caption-small-text-transform: initial;
    --caption-small-letter-spacing: 0.02em;

    @include responsive.responsive(m, below) {
        --heading-large-font-size: 1.75em;
        --heading-medium-font-size: 1.5em;
        --article-heading-large-font-size: 1.75em;
        --article-heading-medium-font-size: 1.125em;
    }
}

[data-theme='br'] {
    --text-font-family: var(--font-family-open-sans);
    --heading-font-family: var(--font-family-lexia);

    --heading-x-small-color: var(--mainColor);
    --heading-x-small-font-family: var(--text-font-family);

    --tag-small-letter-spacing: initial;
    --tag-small-text-transform: initial;
    --tag-small-font-family: var(--text-font-family);

    --tag-medium-letter-spacing: initial;
    --tag-medium-text-transform: initial;
    --tag-medium-font-family: var(--text-font-family);

    --link-list-font-family: var(--heading-font-family);
}

[data-theme='vi'] {
    --text-font-family: var(--font-family-open-sans);
    --heading-font-family: var(--font-family-open-sans);
    --pro-article-heading-font-family: var(--font-family-yrsa);

    --heading-small-font-size: 1.125em;

    --heading-x-small-text-transform: capitalize;
    --heading-x-small-font-weight: var(--font-weight-semi-bold);

    --caption-small-text-transform: initial;
    --caption-small-font-weight: var(--font-weight-semi-bold);
    --caption-medium-text-transform: initial;
    --caption-medium-font-weight: var(--font-weight-semi-bold);

    --tag-small-letter-spacing: initial;
    --tag-small-text-transform: initial;
    --tag-medium-letter-spacing: initial;
    --tag-medium-font-weight: var(--font-weight-bold);
}

[data-theme='gp'] {
    --text-font-family: var(--font-family-titillium);
    --heading-font-family: var(--font-family-titillium);

    --heading-x-small-letter-spacing: 1px;

    --caption-small-font-weight: var(--font-weight-semi-bold);
    --caption-medium-font-weight: var(--font-weight-semi-bold);

    --tag-medium-letter-spacing: 0.4px;
    --tag-medium-font-weight: var(--font-weight-bold);

    --tag-small-letter-spacing: initial;
    --tag-small-font-weight: var(--font-weight-bold);

    --link-medium-letter-spacing: 0.4px;
    --link-medium-text-transform: uppercase;
}

[data-theme='admin'] {
    --text-font-family: var(--font-family-poppins);
    --heading-font-family: var(--font-family-poppins);

    --heading-large-font-size: 1.1em;

    --heading-medium-font-size: 1.1em;

    --heading-x-small-text-transform: initial;
    --heading-x-small-font-weight: var(--font-weight-regular);
}

[data-theme] .inverted {
    --titleColor: var(--invertedTextColor);
    --textColor: var(--invertedTextColor);
    --heading-large-color: var(--invertedTextColor);
    --heading-medium-color: var(--invertedTextColor);
    --heading-small-color: var(--invertedTextColor);
    --heading-x-small-color: var(--invertedTextColor);
    --article-heading-medium-color: var(--invertedTextColor);
    --article-heading-small-color: var(--invertedTextColor);
    --paragraph-large-color: var(--invertedTextColor);
    --paragraph-medium-color: var(--invertedTextColor);
    --caption-medium-color: var(--invertedTextColor);
    --caption-small-color: var(--invertedTextColor);
    --link-medium-color: var(--invertedTextColor);
    --link-list-color: var(--invertedTextColor);
}
