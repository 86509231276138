@use '../mixins';

article.error {
    padding: var(--largePadding);
    border-radius: var(--standardRadius);
    background-color: rgb(var(--invertedBackground));
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;

    p {
        padding: var(--tinyPadding) 0 var(--largePadding);
    }
}

[data-theme='vn'] {
    article.error {
        background-image: url('/images/vn/404.png');
    }
}

[data-theme='vp'] {
    article.error {
        background-image: url('/images/vp/404.png');
    }
}

[data-theme='vpbe'] {
    article.error {
        background-image: url('/images/vpbe/404.png');
    }
}
