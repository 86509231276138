@use '../mixins';

.play-button {
    display: block;
    position: absolute;
    font-size: 15px;
    cursor: pointer;
    z-index: 1;

    &::before {
        content: '';
        display: block;
        position: absolute;
        width: 2.45em;
        height: 2.45em;
        top: -1.4em;
        left: -1.17em;
        background: rgba(var(--invertedBackground), 0.6);
        border-radius: 50%;
        border: 0.1em solid rgb(var(--background));

        [data-theme='gp'] & {
            background: rgb(var(--mainColor));
            border-radius: var(--tinyPadding);
            border: 0.1em solid rgb(var(--mainColor));
        }
    }

    .icon {
        @include mixins.arrow(rgb(var(--background)), rgb(var(--background)), 1em, 'right');

        position: absolute;
        top: -0.55em;
        left: -0.1em;
    }
}
