@use 'mixins';

a {
    color: rgb(var(--linkColor));
    cursor: pointer;
    text-decoration: underline;

    &:hover {
        color: rgb(var(--mainColor));
        text-decoration: none;
    }

    &:global(.small){
      @extend %link-small;
    }
}
