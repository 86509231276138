@use 'sass:color';
@use 'mixins';
@use 'spacing';

:root {
    @extend %spacing;

    --theme: 'none';

    // Main Colors
    --mainColor: 128, 0, 128;
    --mainColorLighter: 255, 192, 203;
    --secondColor: 0, 128, 0;
    --thirdColor: 255, 255, 0;
    --fourthColor: 255, 165, 0;
    --fifthColor: 255, 255, 0;
    --sixthColor: 84, 0, 106;

    // Context Colors
    --textColor: 0, 0, 0;
    --secondaryTextColor: 76, 76, 76;
    --lighterTextColor: 128, 128, 128;
    --titleColor: var(--textColor);
    --linkColor: 0, 0, 255;
    --bettingColor: 0, 128, 0;
    --bettingHoverColor: 51, 152, 87;
    --mediaColor: 255, 165, 0;
    --mediaColorLighter: 0, 174, 255;
    --darkerBettingColor: 0, 128, 0;
    --breakingColor: 255, 165, 0;
    --iconColor: inherit;

    // Social colors
    --whatsappColor: 103, 193, 94;
    --facebookColor: 68, 96, 160;
    --copyLinkColor: 163, 163, 165;
    --instagramColor: 225, 48, 108;
    --youtubeColor: 255, 0, 0;
    --rssColor: 238, 128, 47;
    --tiktokAquaColor: 0, 242, 234;

    // Status Colors
    --errorColor: 255, 0, 0;
    --warningColor: 255, 165, 0;
    --succesColor: 0, 128, 0;
    --lighterSuccessColor: 2, 71, 2;
    --positiveColor: 0, 189, 7;
    --negativeColor: 223, 0, 45;

    // National colors
    --mainNationalColor: 255, 81, 0;
    --secondaryNationalColor: 252, 138, 3;

    // Sections
    --background: 255, 255, 255;
    --alternativeBackground: 237, 237, 237;
    --invertedBackground: 0, 0, 0;
    --lighterInvertedBackground: 26, 26, 31;
    --lightestInvertedBackground: 52, 57, 64;
    --darkestBackground: 0, 0, 0;
    --invertedTextColor: 255, 255, 255;
    --invertedBorder: 104, 104, 104;
    --sectionBack: 232, 232, 232;
    --sectionBackAlternate: var(--background);
    --sectionBorder: 232, 232, 232;
    --sectionSeparationBorder: 209, 209, 210;
    --sectionTextColor: var(--textColor);
    --lightSectionBack: var(--sectionBack);
    --lightSectionBorder: var(--sectionBorder);

    // Table
    --rowColor: 243, 243, 244;

    // Icons
    --standard-icon-size: 1em;
}

[data-theme='vn'],
[data-theme='vp'],
[data-theme='vpbe'] {
    $thirdColor: 0, 142, 255;
    $bettingColor: 0, 127, 45;
    $textColor: 26, 26, 31;
    $darkerBettingColor: color.scale(mixins.rgb-list-to-color($bettingColor), $lightness: -27%);
    $lighterTextColor: color.mix(mixins.rgb-list-to-color($textColor), white, 40%);

    // colors
    --secondColor: 253, 159, 36;
    --thirdColor: #{$thirdColor};
    --fourthColor: 254, 86, 33;
    --bettingColor: #{$bettingColor};
    --darkerBettingColor: #{mixins.color-to-rgb-list($darkerBettingColor)};
    --breakingColor: 254, 86, 33;
    --mediaColor: #{$thirdColor};
    --errorColor: 223, 0, 45;
    --textColor: #{$textColor};
    --lightSectionBack: 243, 243, 244;
    --lightSectionBorder: 243, 243, 244;
    --lighterTextColor: #{mixins.color-to-rgb-list($lighterTextColor)};
    --invertedTextColor: 254, 254, 254;
    --linkColor: #{$thirdColor};
}

[data-theme='vn'] {
    --theme: 'vn';
    --mainColor: 0, 17, 35;
    --mainColorLighter: 4, 34, 92;
    --invertedBackground: 26, 26, 31;
    --mainNationalColor: 252, 138, 3;
    --secondaryNationalColor: 255, 81, 0;
}

[data-theme='vp'] {
    --theme: 'vp';
    --mainColor: 57, 32, 124;
    --mainColorLighter: 45, 84, 177;
    --invertedBackground: 26, 26, 31;
}

[data-theme='vpbe'] {
    --theme: 'vpbe';
    --mainColor: 212, 29, 29;
    --mainColorLighter: 255, 69, 69;
    --invertedBackground: 26, 26, 31;

    // National colors
    --mainNationalColor: 212, 29, 29;
    --secondaryNationalColor: 255, 69, 69;
}

[data-theme='vi'] {
    $secondColor: 227, 6, 19;
    $thirdColor: 0, 142, 255;
    $bettingColor: 0, 133, 40;
    $textColor: 26, 26, 31;
    $darkerBettingColor: color.scale(mixins.rgb-list-to-color($bettingColor), $lightness: -27%);
    $lighterTextColor: color.mix(mixins.rgb-list-to-color($textColor), white, 40%);
    $lighterSecondColor: color.mix(mixins.rgb-list-to-color($secondColor), white, 60%);
    $lightSectionBack: 234, 234, 234;
    $sectionBorder: 216, 216, 216;

    // colors
    --mainColor: 0, 158, 224;
    --mainColorLighter: 0, 126, 179;
    --titleColor: #{$textColor};
    --secondColor: #{$secondColor};
    --secondColorLighter: #{mixins.color-to-rgb-list($lighterSecondColor)};
    --thirdColor: #{$thirdColor};
    --fourthColor: 254, 86, 33;
    --bettingColor: #{$bettingColor};
    --darkerBettingColor: #{mixins.color-to-rgb-list($darkerBettingColor)};
    --breakingColor: 254, 86, 33;
    --errorColor: 223, 0, 45;
    --sectionBack: 240, 240, 240;
    --sectionBackAlternate: 250, 250, 250;
    --sectionBorder: #{$sectionBorder};
    --lighterInvertedBackground: 29, 35, 43; // Same as GP
    --lightSectionBack: 243, 243, 244;
    --lightSectionBorder: #{$sectionBorder};
    --invertedBackground: #{$textColor};

    // Table
    --rowColor: 240, 240, 240;

    // Font colors
    --textColor: #{$textColor};
    --lighterTextColor: #{mixins.color-to-rgb-list($lighterTextColor)};
    --invertedTextColor: 254, 254, 254;
    --linkColor: #{$thirdColor};
}

[data-theme='gp'] {
    $mainColor: 208, 2, 27;
    $fourthColor: 0, 118, 255;

    // colors
    --theme: 'gp';
    --mainColor: #{$mainColor};
    --mainColorLighter: #{$mainColor};
    --mainHoverColor: 173, 8, 28;
    --secondColor: #{$mainColor};
    --thirdColor: #{$mainColor};
    --fourthColor: #{$fourthColor};
    --iconColor: #{$mainColor};
    --invertedBackground: 18, 22, 27;
    --lighterInvertedBackground: 29, 35, 43;
    --darkestBackground: 26, 26, 31;
    --breakingColor: 254, 86, 33;
    --errorColor: 255, 69, 69;
    --mediaColor: #{$fourthColor};
    --positiveColor: 90, 185, 129;
    --negativeColor: 229, 46, 43;
    --sectionBack: 15, 28, 45;
    --sectionBorder: 221, 225, 229;
    --lightSectionBack: 246, 246, 246;
    --lightSectionBorder: 232, 232, 232;
    --bettingColor: 0, 122, 80;
    --darkerBettingColor: 0, 84, 55;

    // Font colors
    --invertedTextColor: 255, 255, 255;
    --lighterTextColor: 155, 155, 155;
    --linkColor: var(--mainColor);
    --sectionTextColor: 255, 255, 255;
}

[data-theme='br'] {
    $mainColor: 0, 204, 255;
    $fourthColor: 255, 0, 0;
    $fifthColor: 229, 200, 102;

    // colors
    --theme: 'br';
    --mainColor: #{$mainColor};
    --mainColorLighter: #{$mainColor};
    --mainHoverColor: 2, 183, 229;
    --invertedBackground: 23, 27, 31;
    --secondColor: #{$mainColor};
    --thirdColor: #{$mainColor};
    --fourthColor: #{$fourthColor};
    --fifthColor: #{$fifthColor};
    --breakingColor: 255, 0, 0;
    --mediaColor: #{$mainColor};
    --lightSectionBack: 243, 242, 241;
    --sectionBack: 185, 184, 185;

    // Font colors
    --linkColor: var(--mainColor);
    --invertedTextColor: 255, 255, 255;

    @extend %carousel-shape-br;
}

[data-theme='admin'] {
    $mainColor: 116, 120, 141;
    $textFont: poppins, helvetica, arial, sans-serif;

    // colors
    --theme: 'admin';
    --mainColor: #{$mainColor};
    --thirdColor: #{$mainColor};
    --background: 248, 248, 251;
    --positiveColor: 52, 195, 143;
    --negativeColor: 244, 106, 106;
    --sectionBack: 248, 248, 251;
    --sectionBorder: 239, 242, 247;
    --lightSectionBack: 255, 255, 255;
    --lightSectionBorder: 206, 212, 218;
    --standardRadius: 4px;

    // Font colors
    --titleColor: 73, 80, 87;
    --textColor: 116, 120, 141;
    --linkColor: inherit;
    --lighterTextColor: 165, 165, 165;
}
