@use 'mixins';

.ad {
    display: flex !important;
    padding: var(--smallPadding) 0;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(var(--lightSectionBorder));
    border-radius: var(--standardRadius);
    flex-direction: column;
    text-align: center;

    > p {
        display: none;
        font-size: 0.75em;
        color: rgb(var(--lighterTextColor));
        margin-bottom: var(--smallPadding);
        min-height: 0 !important;
    }

    > div {
        display: block !important;
        width: 100%;
    }

    &.has-background {
        background: rgb(var(--lightSectionBack));
    }

    &.h90 {
        margin-bottom: var(--standardPadding);

        > * {
            min-height: 90px;
        }
    }

    &.h250 {
        > * {
            min-height: 250px;
        }
    }

    &.h370 {
        > * {
            min-height: 370px;
        }
    }

    &.h480 {
        > * {
            min-height: 480px;
        }
    }

    &.h600 {
        > * {
            min-height: 600px;
        }
    }

    &.native {
        padding: 0;
        border: none;
        float: none !important;
    }

    &.mobile-only {
        display: none !important;
    }
}

@include mixins.responsive(m, below) {
    .ad {
        margin: 0 var(--smallPadding) !important;
        border-radius: 0;

        > p {
            display: block;
        }

        &.mobile-h90 {
            margin-bottom: var(--standardPadding);

            > * {
                min-height: 90px;
            }
        }

        &.mobile-h250 {
            > * {
                min-height: 250px;
            }
        }

        &.mobile-h370 {
            > * {
                min-height: 370px;
            }
        }

        &.mobile-h480 {
            > * {
                min-height: 480px;
            }
        }

        &.mobile-h600 {
            > * {
                min-height: 600px;
            }
        }

        &.h600 {
            margin: 0 !important;
        }

        &.mobile-only {
            display: flex !important;
        }

        &.desktop-only {
            display: none !important;
        }
    }
}
