%spacing {
    // Padding
    --tinyPadding: 4px;
    --smallPadding: 8px;
    --standardPadding: 12px;
    --mediumPadding: 16px;
    --largePadding: 24px;
    --hugePadding: 40px;

    // radius
    --standardRadius: 2px;
    --largeRadius: 4px;
    --circleRadius: 50%;

    // Borders
    --standardBorderWidth: 2px;

    // Icon
    --standard-icon-size: 1em;

    // Marquee distance
    --marquee-distance: -100%;

    [data-theme='vi'] {
        --standardBorderWidth: 3px;
    }
}
